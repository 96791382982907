import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import MainPage from './pages/MainPage';
import Login from './backoffice/login/Login';
import Dashboard from './backoffice/dashboard/Dashboard';
import BackofficeLayout from './backoffice/layout/BackofficeLayout';
import useAuth from './provider/auth.provider';
import TrustedCompanies from './backoffice/trusted-companies/TrustedCompanies';
import Department from './backoffice/department/Department';
import DepartmentDetail from './pages/DepartmentDetail';
import TeamDetail from './pages/TeamDetail';
import DepartmentDetailEdit from './backoffice/department/DepartmentDetailEdit';
import TeamDetailEdit from './backoffice/dashboard/TeamDetailEdit';

const Router = () => {

	const RequireAuth = ({ children }) => {
		const { currentUser } = useAuth();
		const location = useLocation();
		const navigate = useNavigate();

		useEffect(() => {
		if (currentUser) {
			if (location.pathname.includes("backoffice")) {
				navigate('/backoffice/dashboard');
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [currentUser]);

		if (!currentUser) {
		return (
			<Navigate to={'/backoffice'} state={{ from: location }} replace />
		);
		}

		return children;
  	};

  return (
	<Routes>
	  <Route path='/' element={<MainPage />} />
	  <Route path='/departments/:id' element={<DepartmentDetail />} />
	  <Route path='/teams/:id' element={<TeamDetail />} />
	  <Route path='/backoffice' element={<Login />} />
	  <Route path='/backoffice' element={<RequireAuth><BackofficeLayout /></RequireAuth>}>
	  	<Route path={'/backoffice/dashboard'} element={<Dashboard />} />
		<Route path="/backoffice/team/detail/:id" element={<TeamDetailEdit />} />
	  	<Route path={"/backoffice/clients"} element={<TrustedCompanies />} />
	  	<Route path={"/backoffice/department"} element={<Department />} />
		<Route path={"/backoffice/department/detail/:id"} element={<DepartmentDetailEdit />} />
	  </Route>
	</Routes>
  )
}

export default Router
