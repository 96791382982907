/* eslint-disable react-hooks/exhaustive-deps */
import { App, Button, Image, Table } from 'antd'
import Title from 'antd/es/typography/Title'
import React, { useEffect, useState } from 'react'
import AddEditCompanyDialog from './components/AddEditCompanyDialog';
import { addCompany, deleteCompany, getCompanies, updateCompany, uploadCompanyImage } from '../../firebase/firebase';

const TrustedCompanies = () => {
	const { notification, modal } = App.useApp();
	const [companies, setCompanies] = useState([]);
	const [loading, setLoading] = useState(false);

	const [companyToEdit, setCompanyToEdit] = useState(null);
	const [showAddEditCompanyModal, setAddEditCompanyModal] = useState(false);
	const [isEdit, setIsEdit] = useState(false);

		useEffect(() => {
			getAllCompanies()
		}, [])
	
		const getAllCompanies = async () => {
			setLoading(true)
			getCompanies().then((data) => {
				setCompanies(data)
			}).catch((error) => {
				notification.error({
				message: error.message
			});
			}).finally(() => {
				setLoading(false)
			})
		}

			const uploadImage = async (image, uid) => {
				try{
					const imageUrl = await uploadCompanyImage(image, uid);
					return imageUrl;
				} catch (error) {
					notification.error({
					message: error.message
				});
				}
			}

	const handleAddEditCompany = async () => {
		setLoading(true)
				try {
					let imageUrl = companyToEdit?.image;
		
					if (isEdit) {
						if (typeof companyToEdit.image === 'object') {
							imageUrl = await uploadImage(companyToEdit.image, companyToEdit.key);
						}
						await updateCompany({ ...companyToEdit, image: imageUrl });
						notification.success({
							message: 'Company updated successfully',
						});
					} else {
						const newTeamMember = await addCompany({ ...companyToEdit, image: '', createdAt: new Date() });
						console.log(newTeamMember)
						if (typeof companyToEdit.image === 'object') {
							imageUrl = await uploadImage(companyToEdit.image, newTeamMember.key);
							await updateCompany({ ...newTeamMember, image: imageUrl });
						}
						notification.success({
							message: 'Company added successfully',
						});
					}
					getAllCompanies();
					setAddEditCompanyModal(false);
					setCompanyToEdit(null);
					setIsEdit(false);
				} catch (error) {
					console.log(error)
					notification.error({
						message: error.message,
					});
				} finally {
					setLoading(false);
				}
	};

const handleDeleteClick = (uid) => {
	modal.confirm({
		title: 'Are you sure you want to delete this company?',
		onOk: () => {
			setLoading(true)
			deleteCompany(uid).then(() => {
				notification.success({
					message: 'Company deleted successfully',
				});
				getAllCompanies();
			}).catch((error) => {
				notification.error({
					message: error.message
				});
			}).finally(() => {
				setLoading(false)
			})
		}
	})
}

	return (
		<div>
			<div className='flex flex-row justify-between items-center'>
				<Title level={5}>Trusted Companies</Title>
				<Button
					type="primary"
					onClick={() => {
						setAddEditCompanyModal(true);
						setIsEdit(false);
					}}
				>Add New</Button>
			</div>
			<div className="flex flex-col gap-4 bg-white mt-10">
			<Table
				loading={loading}
				dataSource={companies}
				columns={[
					{
						title: "Name",
						dataIndex: "name",
						key: "name",
					},
					{
						title: "Image",
						dataIndex: "image",
						key: "designation",
						render: (text) => <Image height={50} width={100} src={text} />
					},
					{
						title: "Actions",
						key: "actions",
						render: (text, record) => (
							<div>
								<Button
									color="primary"
									size="small"
									variant="outlined"
									onClick={() => {
										setCompanyToEdit(record)
										setIsEdit(true)
										setAddEditCompanyModal(true)
									}}
								>Edit</Button>
								<Button
									color="danger"
									size="small"
									variant="outlined"
									className="ml-2"
									onClick={() => {
										handleDeleteClick(record.key)
									}
									}
								>Delete</Button>
							</div>
						),
					}
				]}
				pagination={false}
				scroll={{ x: 'max-content' }}
		/>
		</div>
			<AddEditCompanyDialog
				isEdit={isEdit}
				showAddEditCompanyModal={showAddEditCompanyModal}
				addEditCompany={handleAddEditCompany}
				onCancel={() => {
					setAddEditCompanyModal(false);
					setCompanyToEdit(null);
				}}
				companyToEdit={companyToEdit}
				setCompanyToEdit={setCompanyToEdit}
				loading={loading}
			/>
		</div>
	)
}

export default TrustedCompanies
