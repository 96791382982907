/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Navigation from '../Components/Navigation'
import { useParams } from 'react-router-dom'
import { App, Image, Spin } from 'antd'
import { getTeamById } from '../firebase/firebase'
import Title from 'antd/es/typography/Title'
import Paragraph from 'antd/es/typography/Paragraph'

const TeamDetail = () => {
	const {id} = useParams()
	const [team, setTeam] = useState(null)
	const [loading, setLoading] = useState(false)
	const { notification } = App.useApp();

	useEffect(() => {
		if (id) {
			getTeam()
		}
	}, [id])

	const getTeam = async () => {
		setLoading(true)
		getTeamById(id).then((data) => {
			setTeam(data)
		}
		).catch((error) => {
			notification.error({
				message: error.message
	  		});
		}).finally(() => {
			setLoading(false)
		})
	}

	if (loading && !team) {
		return <div className='flex justify-center items-center mt-10'><Spin size='large' spinning /></div>
	}

	return (
		<div>
			<Navigation />
			{(!loading && team) &&(
				<section id='team-detail' className='team-detail mt-10'>
					<div className='px-5 flex items-start justify-center gap-4'>
						<div className='team-detail-img'>
							<Image src={team.image} alt='team' />
						</div>
						<div className='team-detail-info w-1/2'>
							<Title level={1}>{team.name}</Title>
							<Title level={3}>{team.designation}</Title>
							<Paragraph>{team.description}</Paragraph>
						</div>
					</div>
				</section>
			)}
		</div>
	)
}

export default TeamDetail
