import { Form, Modal, Input, Image } from 'antd';
import React from 'react'

const AddEditTeamDialog = ({
	isEdit,
	showAddEditTeamModal,
	addEditTeam,
	onCancel,
	teamToEdit,
	setTeamToEdit,
	loading
}) => {

	const getImageSrc = () => {
		if (!teamToEdit?.image) return;
		if (typeof teamToEdit.image === 'string') return teamToEdit.image;
		return URL.createObjectURL(new Blob([teamToEdit.image]));
	};

return (
<Modal
				title={isEdit ? "Edit Team mate" : "New Team mate"}
				open={showAddEditTeamModal}
				onOk={addEditTeam}
				onCancel={onCancel}
		cancelButtonProps={{ disabled: loading }}
		okButtonProps={{
			disabled: (!teamToEdit?.name || !teamToEdit?.designation || !teamToEdit?.description || !teamToEdit?.image),
			loading: loading,
		}}
		>
		<Form.Item label={"Name"} layout="vertical">
			<Input
					name='name'
							placeholder={"Name"}
				value={teamToEdit?.name}
				onChange={(e) => setTeamToEdit((prev) => ({ ...prev, [e.target.name]: e.target.value }))}
						/>
				</Form.Item>
		<Form.Item label={"Designation"} layout="vertical">
			<Input
					name='designation'
							placeholder={"Designation"}
				value={teamToEdit?.designation}
				onChange={(e) => setTeamToEdit((prev) => ({ ...prev, [e.target.name]: e.target.value }))}
						/>
				</Form.Item>
		<Form.Item label={"Description"} layout="vertical">
			<Input.TextArea
				rows={4}
					name='description'
							placeholder={"Description"}
				value={teamToEdit?.description}
				onChange={(e) => setTeamToEdit((prev) => ({ ...prev, [e.target.name]: e.target.value }))}
						/>
				</Form.Item>
		<Form.Item label={"Image"} layout="vertical">
			<Input
				type='file'
					name='image'
				placeholder={"Image"}
				onChange={(e) => setTeamToEdit((prev) => ({ ...prev, [e.target.name]: e.target.files[0] }))}
				/>
		</Form.Item>
		<Image height={100} width={100} src={getImageSrc()} />
	</Modal>
)
}

export default AddEditTeamDialog
