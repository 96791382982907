/* eslint-disable react-hooks/exhaustive-deps */
import Title from 'antd/es/typography/Title'
import React, { useEffect, useState } from 'react'
import { deleteTeam, getTeam, uploadTeamImage } from '../../firebase/firebase'
import { App, Button, Image, Table } from 'antd'
import AddEditTeamDialog from './components/AddEditTeamDialog'
import { addTeam, updateTeam } from '../../firebase/firebase'
import { EyeOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

const Dashboard = () => {
	const [team, setTeam] = useState([])
	const { notification, modal } = App.useApp();
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const [teamToEdit, setTeamToEdit] = useState(null);
	const [showAddEditTeamModal, setAddEditTeamModal] = useState(false);
	const [isEdit, setIsEdit] = useState(false);

	useEffect(() => {
		getAllTeam()
	}, [])

	const getAllTeam = async () => {
		setLoading(true)
		getTeam().then((data) => {
			setTeam(data)
		}).catch((error) => {
			notification.error({
			message: error.message
		});
		}).finally(() => {
			setLoading(false)
		})
	}

	const uploadImage = async (image, uid) => {
		try{
			const imageUrl = await uploadTeamImage(image, uid);
			return imageUrl;
		} catch (error) {
			notification.error({
			message: error.message
		});
		}
	}

	const handleAddEditTeam = async () => {
		setLoading(true)
		try {
			let imageUrl = teamToEdit?.image;

			if (isEdit) {
				if (typeof teamToEdit.image === 'object') {
					imageUrl = await uploadImage(teamToEdit.image, teamToEdit.key);
				}
				await updateTeam({ ...teamToEdit, image: imageUrl });
				notification.success({
					message: 'Employee updated successfully',
				});
			} else {
				const newTeamMember = await addTeam({ ...teamToEdit, image: '', createdAt: new Date() });
				if (typeof teamToEdit.image === 'object') {
					imageUrl = await uploadImage(teamToEdit.image, newTeamMember.key);
					await updateTeam({ ...newTeamMember, image: imageUrl });
				}
				notification.success({
					message: 'Employee added successfully',
				});
			}
			getAllTeam();
			setAddEditTeamModal(false);
			setTeamToEdit(null);
			setIsEdit(false);
		} catch (error) {
			console.log(error)
			notification.error({
				message: error.message,
			});
		} finally {
			setLoading(false);
		}
	}

	const handleDeleteClick = async (uid) => {
		modal.confirm({
			title: 'Are you sure you want to delete this employee?',
			onOk: async () => {
				try {
					setLoading(true)
					await deleteTeam(uid);
					notification.success({
						message: 'Employee deleted successfully',
					});
					getAllTeam();
				} catch (error) {
					notification.error({
						message: error.message,
					}) 
				} finally {
						setLoading(false)
					}
			},
		});
	}

	const handleDetailClick = (uid) => {
		navigate(`/backoffice/team/detail/${uid}`)
	}

return (
<div>
	<div className='flex flex-row justify-between items-center'>
		<Title level={5}>Team</Title>
		<Button
			type="primary"
			onClick={() => {
				setAddEditTeamModal(true)
				setIsEdit(false)
			}}
		>Add New</Button>
	</div>
	<div className="flex flex-col gap-4 bg-white mt-10">
	<Table
				loading={loading}
				dataSource={team}
				columns={[
					{
						title: "Name",
						dataIndex: "name",
						key: "name",
					},
					{
						title: "Designation",
						dataIndex: "designation",
						key: "designation",
					},
					{
						width: 250,
						title: "Description",
						dataIndex: "description",
						key: "description",
						render: (text) => {
							return (
								<p style={{ 
									overflow: 'hidden', 
									textOverflow: 'ellipsis', 
									display: '-webkit-box', 
									WebkitLineClamp: 2, 
									WebkitBoxOrient: 'vertical' 
								}}>
									{text}
								</p>
							)
						}
					},
					{
						title: "Image",
						dataIndex: "image",
						key: "designation",
						render: (text) => <Image height={50} width={100} src={text} />
					},
					{
						title: "Actions",
						key: "actions",
						render: (text, record) => (
							<div>
								<Button
									color="primary"
									size="small"
									variant="outlined"
									onClick={() => {
										setTeamToEdit(record)
										setIsEdit(true)
										setAddEditTeamModal(true)
									}}
								>Edit</Button>
								<Button
									color="danger"
									size="small"
									variant="outlined"
									className="ml-2"
									onClick={() => {
										handleDeleteClick(record.key)
									}}
								>Delete</Button>
							</div>
						),
					},
					{
						title: "Detail",
						key: "detail",
						render: (text, record) => (
							<Button
								color="primary"
								size="small"
								variant="outlined"
								icon={<EyeOutlined />}
								iconPosition='left'
								onClick={() => {
									handleDetailClick(record.key)
								}}
							>Detail</Button>
						),
					}
				]}
				pagination={false}
				scroll={{ x: 'max-content' }}
		/>
	</div>
	{showAddEditTeamModal && (<AddEditTeamDialog
		isEdit={isEdit}
		showAddEditTeamModal={showAddEditTeamModal}
		addEditTeam={handleAddEditTeam}
		onCancel={() => {
			setAddEditTeamModal(false)
			setTeamToEdit(null)
			setIsEdit(false)
		}}
		teamToEdit={teamToEdit}
		setTeamToEdit={setTeamToEdit}
		loading={loading}
	/>)}
</div>
)
}

export default Dashboard
