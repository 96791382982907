import { createContext, useContext, useMemo, useState } from "react";

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
	const [currentUser, setCurrentUser] = useState(null);
	const setUser = (user) => {
	  setCurrentUser(user);
	}

	const value = useMemo(() => ({
		currentUser,
		setUser,
	}), [currentUser]);

	  return (
	<AuthContext.Provider value={value}>
	  {children}
	</AuthContext.Provider>
  );
};

export default function useAuth() {
  return useContext(AuthContext);
}