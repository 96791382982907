import { Form, Modal, Input, Image } from 'antd';
import React from 'react'

const AddEditDepartmentModal = ({
	isEdit,
	showAddEditDepartmentModal,
	addEditDepartment,
	onCancel,
	departmentToEdit,
	setDepartmentToEdit,
	loading
}) => {

	const getImageSrc = () => {
		if (!departmentToEdit?.image) return;
		if (typeof departmentToEdit.image === 'string') return departmentToEdit.image;
		return URL.createObjectURL(new Blob([departmentToEdit.image]));
	};

return (
		<Modal
			title={isEdit ? "Edit Department" : "New Department"}
			open={showAddEditDepartmentModal}
			onOk={addEditDepartment}
			onCancel={onCancel}
			cancelButtonProps={{ disabled: loading }}
			okButtonProps={{
				disabled: (!departmentToEdit?.name || !departmentToEdit?.image),
				loading: loading,
			}}
		>
			<Form.Item label={"Name"} layout="vertical">
				<Input
					name='name'
					placeholder={"Name"}
					value={departmentToEdit?.name}
					onChange={(e) => setDepartmentToEdit((prev) => ({ ...prev, [e.target.name]: e.target.value }))}
				/>
			</Form.Item>
			<Form.Item label={"Name"} layout="vertical">
				<Input.TextArea
					rows={4}
					name='description'
					placeholder={"Description"}
					value={departmentToEdit?.description}
					onChange={(e) => setDepartmentToEdit((prev) => ({ ...prev, [e.target.name]: e.target.value }))}
				/>
			</Form.Item>
			<Form.Item label={"Cover Image (Team/Department)"} layout="vertical">
				<Input
					type='file'
					name='image'
					placeholder={"Image"}
					onChange={(e) => setDepartmentToEdit((prev) => ({ ...prev, [e.target.name]: e.target.files[0] }))}
				/>
			</Form.Item>
			<Image height={100} width={100} src={getImageSrc()} />
	</Modal>
)
}

export default AddEditDepartmentModal
