import React from 'react'
import './App.scss'
import { AnimatePresence } from 'framer-motion'
import Router from './Router'
import { ConfigProvider,  App as AntdApp } from 'antd'
import en from 'antd/locale/en_US';
import { AuthProvider } from './provider/auth.provider'
// import Svg from './SubComponents/sections/Svg'


const App = () => {

  return (
    <AuthProvider>
    <AnimatePresence exitBeforeEnter>
      <ConfigProvider
              locale={en}
              theme={{
                token: {
                  colorPrimary: "#38B6E9",
                  colorInfo: "#38B6E9",
                  colorTextSecondary: "#38B6E9",
                  colorTextBase: "#454545",
                  colorSuccess: "#a6ce39",
                  colorWarning: "rgba(254, 174, 25, 1)",
                  fontSize: 16,
                  borderRadius: 6,
                  fontFamily: "Inter ui-sans-serif, system-ui, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
                  wireframe: false,
                },
                components: {
                  Button: {
                    borderRadius: 6,
                    primaryColor: "#FCFCFC !important",
                    colorBgContainerDisabled: "#B3B3B3",
                    borderColorDisabled: "#B3B3B3",
                    controlHeight: 42,
                    fontSize: 18,
                    fontWeight: "700",
                    defaultColor: "#5E5E5E !important",
                    // defaultHoverColor: "#5E5E5E !important",
                    // defaultHoverBorderColor: "#000 !important",
                    algorithm: true,
                    },
                  Input: {
                    borderRadius: 6,
                    colorBorder: "#38B6E9",
                    controlHeight: 42,
                    fontSize: 17,
                    colorTextPlaceholder: "#B3B3B3",
                    controlOutlineWidth: 2
                  },
                  InputNumber: {
                    borderRadius: 6,
                    colorBorder: "#38B6E9",
                    controlHeight: 30,
                    fontSize: 17,
                    colorTextPlaceholder: "#B3B3B3",
                  },
                  DatePicker: {
                    borderRadius: 6,
                    colorBorder: "#38B6E9",
                    controlHeight: 42,
                    fontSize: 17,
                    colorTextPlaceholder: "#B3B3B3",
                    controlOutlineWidth: 2,
                  },
                  Select: {
                    borderRadius: 6,
                    colorBorder: "#38B6E9",
                    controlHeight: 42,
                    fontSize: 17,
                    colorTextPlaceholder: "#B3B3B3",
                    controlOutlineWidth: 2
                  },
                  Form: {
                    labelFontSize: 14,
                    labelColor: "#454545",
                  },
                  Menu: {
                    itemSelectedColor: "#FCFCFC !important",
                    itemColor: "#1f2937 !important",
                    itemSelectedBg: "#38B6E9 !important",
                    fontSize: 14,
                  },
                  Table: {
                    fontSize: 16,
                    colorTextHeading: "#454545",
                    colorText: "#808080"
                  },
                  Card: {
                    colorBgBase: "#A3A3A3",
                    boxShadow: "0px 4px 4px rgba(163, 163, 163, 0.06)",
                  },
                },
                hashed: false,
              }}
            >
              <AntdApp>

      <Router />
              </AntdApp>
              </ConfigProvider>
    </AnimatePresence>
    </AuthProvider>
  )
}

export default App