import React from 'react'
import { Spin } from 'antd'
import { useNavigate } from 'react-router-dom'

const Attorney = ({ team, loading }) => {
  const navigate = useNavigate()

  const handleTeamClick = (id) => {
    navigate(`/teams/${id}`)
  }

  return (
    <section id='attorney' className='attorney'>
      <h4 className='tophead'>Our team</h4>

      {
      (team.length === 0 && loading) && <Spin size="large" />
      }

      <div className="attorney-cont">
        {
          team.length > 0 && team.map((lawyer, index) => (
            <div key={index} className="profile" onClick={() => handleTeamClick(lawyer.key)}>
              <img src={lawyer.image} alt="lawyer" style={{ backgroundColor: 'grey' }} />
              <div className="overlay">
                <h4>{lawyer.name}</h4>
                <h5>{lawyer.designation}</h5>
                <p>{lawyer.description}</p>
              </div>
            </div>
          ))
        }
      </div>
    </section>
  )
}

export default Attorney