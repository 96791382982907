import { initializeApp } from "firebase/app";
import { getFirestore, getDocs, collection, setDoc, doc, addDoc, deleteDoc, orderBy, query, getDoc } from 'firebase/firestore/lite';
import { getAuth, signInWithEmailAndPassword  } from "firebase/auth";
import "firebase/compat/auth";
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAw0oE6b_wzlpOhLETNDBLjnWHAyZ_c0gw",
  authDomain: "shahandshah-28e6b.firebaseapp.com",
  projectId: "shahandshah-28e6b",
  storageBucket: "shahandshah-28e6b.appspot.com",
  messagingSenderId: "131620055394",
  appId: "1:131620055394:web:82c64fda460b7dd8da243b"
};

// Initialize Firebase
export const firebase = initializeApp(firebaseConfig);
export const db = getFirestore(firebase);

const auth = getAuth();

export const login = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    return user;
  } catch (error) {
    throw error;
  }
}

export const logout = async () => {
  try {
    await auth.signOut();
  } catch (error) {
    throw error;
  }
}

export const getTeam = async () => {
  const team = [];
  const querySnapshot = await getDocs(query(collection(db, "team"), orderBy("createdAt", "asc")));
  querySnapshot.forEach((doc) => {
    team.push({...doc.data(), key: doc.id});
  });
  return team;
}

export const getTeamById = async (uid) => {
  const docRef = doc(db, "team", uid);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return { ...docSnap.data(), key: docSnap.id };
  } else {
    return null;
  }
}

export const uploadTeamImage = async (image, uid) => {
  const storage = getStorage(firebase);
  const storageRef = ref(storage, `team/${uid}`);

  try {
    const snapshot = await uploadBytes(storageRef, image);
    const url = await getDownloadURL(snapshot.ref);
    return url;
  } catch (error) {
    throw error;
  }
}

export const updateTeam = async (team) => {
  try {
    await setDoc(doc(db, "team", team.key), team);
  } catch (error) {
    throw error;
  }
}

export const addTeam = async (team) => {
  try {
    const docRef = await addDoc(collection(db, "team"), team);
    return { ...team, key: docRef.id };
  } catch (error) {
    throw error;
  }
}

export const deleteTeam = async (uid) => {
  try {
    await deleteDoc(doc(db, "team", uid));
    const storage = getStorage(firebase);
    const storageRef = ref(storage, `team/${uid}`);
    await deleteObject(storageRef);
  } catch (error) {
    throw error;
  }
}


export const getCompanies = async () => {
  const companies = [];
  const querySnapshot = await getDocs(collection(db, "companies"));
  querySnapshot.forEach((doc) => {
    companies.push({...doc.data(), key: doc.id});
  });
  return companies;
}

export const uploadCompanyImage = async (image, uid) => {
  const storage = getStorage(firebase);
  const storageRef = ref(storage, `companies/${uid}`);

  try {
    const snapshot = await uploadBytes(storageRef, image);
    const url = await getDownloadURL(snapshot.ref);
    return url;
  } catch (error) {
    throw error;
  }
}

export const updateCompany = async (company) => {
  try {
    await setDoc(doc(db, "companies", company.key), company);
  } catch (error) {
    throw error;
  }
}

export const addCompany = async (company) => {
  try {
    const docRef = await addDoc(collection(db, "companies"), company);
    return { ...company, key: docRef.id };
  } catch (error) {
    throw error;
  }
}

export const deleteCompany = async (uid) => {
  try {
    await deleteDoc(doc(db, "companies", uid));
    const storage = getStorage(firebase);
    const storageRef = ref(storage, `companies/${uid}`);
    await deleteObject(storageRef);
  } catch (error) {
    throw error;
  }
}

// Departments

export const getDepartments = async () => {
  const departments = [];
  const querySnapshot = await getDocs(query(collection(db, "departments"), orderBy("createdAt", "asc")));
  querySnapshot.forEach((doc) => {
    departments.push({...doc.data(), key: doc.id});
  });
  return departments;
}

export const getDepartmentById = async (uid) => {
  const docRef = doc(db, "departments", uid);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return { ...docSnap.data(), key: docSnap.id };
  } else {
    return null;
  }
}

export const uploadDepartmentImage = async (image, uid) => {
  const storage = getStorage(firebase);
  const storageRef = ref(storage, `departments/${uid}`);

  try {
    const snapshot = await uploadBytes(storageRef, image);
    const url = await getDownloadURL(snapshot.ref);
    return url;
  } catch (error) {
    throw error;
  }
}

export const updateDepartment = async (department) => {
  try {
    await setDoc(doc(db, "departments", department.key), department);
  } catch (error) {
    throw error;
  }
}

export const addDepartment = async (department) => {
  try {
    const docRef = await addDoc(collection(db, "departments"), department);
    return { ...department, key: docRef.id };
  } catch (error) {
    throw error;
  }
}

export const deleteDepartment = async (uid) => {
  try {
    await deleteDoc(doc(db, "departments", uid));
    const storage = getStorage(firebase);
    const storageRef = ref(storage, `departments/${uid}`);
    await deleteObject(storageRef);
  } catch (error) {
    throw error;
  }
}
