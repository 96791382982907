import React from 'react'
import Button from './Button'
import { Spin } from 'antd'
import { useNavigate } from 'react-router-dom'
const Work = ({ departments, loading }) => {
    const navigate = useNavigate()
    const handleLearnMoreClick = (key) => {
        navigate(`/departments/${key}`)
    }
    return ( 
        <section className = 'work' id='work'>
            {
                (departments.length === 0 && loading) && <Spin size="large" />
            }
            {departments.length > 0 && departments.map((department, index) => (
                <div className = "item" key={`department.key-${index}`} >
                <div className = "item-img" >
                    <img src = { department.image } alt = "lawyer" />
                </div> 
                <div className = "item-text" >
                <h4 className = "tophead" >{department.name} </h4> 
                <p>{department.description}</p> 
                    <Button title = 'Learn more' onClick={() => handleLearnMoreClick(department.key)} />
                </div>
            </div> 
            ))}
    </section>
    )
}

export default Work