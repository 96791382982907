import { faGavel } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { App, Button, Form, Input } from 'antd'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { login } from '../../firebase/firebase'
import useAuth from '../../provider/auth.provider'

const Login = () => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const { notification } = App.useApp();
	const { setUser } = useAuth();

	const handleLogin = (values) => {
	setLoading(true);
	login(values.email, values.password).then((response) => {
		console.log(response);
		setUser(response);
		navigate('/backoffice/dashboard');
	})
	.catch((error) => {
		notification.error({
			message: error.message
		});
	})
	.finally(() => {
		setLoading(false);
	});
}

	return (
		<div class="flex h-full flex-col justify-center px-6 py-12 lg:px-8">
			<div class="sm:mx-auto sm:w-full sm:max-w-sm">
				<div className='flex flex-col items-center logo'>
					<FontAwesomeIcon icon={faGavel} />
					<h5 className='mt-4'>S&S Law Associates</h5>
					</div>
				<h2 class="mt-10 text-center text-2xl/9 font-bold tracking-tight text-gray-900">Sign in to your account</h2>
				<div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
					<Form
						layout="vertical"
						autoComplete="off"
						form={form}
						onFinish={handleLogin}
						name="login"
						requiredMark={false}
						initialValues={{
							email: '',
							password: ''
						}}
						scrollToFirstError
					>
						<Form.Item
							label={'Email'}
							layout="vertical"
							name="email"
							rules={[
								{ required: true, type: 'email' }
							]}
						>
							<Input placeholder="Email" />
						</Form.Item>
						<Form.Item
							label={'Password'}
							layout="vertical"
							name="password"
							rules={[
								{ required: true, type: 'string' }
							]}
						>
							<Input.Password placeholder="Password" />
						</Form.Item>
						<Form.Item>
						<Button loading={loading} type="primary" htmlType="submit" className="w-full">
							{'Login'}
						</Button>
					</Form.Item>
					</Form>
				</div>
			</div>
		</div>
	)
}

export default Login
