import { Layout } from 'antd'
import React, { useState } from 'react'
import SideBar from './sidebar/Sidebar'
import { Outlet } from 'react-router-dom';

const { Content } = Layout;

const BackofficeLayout = () => {
	const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(true);
  return (
	<Layout style={{ minHeight: "100vh" }}>
		<Layout>
			<SideBar
				isSidebarCollapsed={isSidebarCollapsed}
				setIsSidebarCollapsed={setIsSidebarCollapsed}
			/>
			<Layout>
          <Content
            style={{
              margin: 0,
              minHeight: 280,
            }}
          >
			<div className="pageBody flex flex-col gap-4 p-5 md:p-5 md:py-8">
				<Outlet />
			</div>
		  </Content>
		  </Layout>
		</Layout>
	</Layout>
  )
}

export default BackofficeLayout
