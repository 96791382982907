import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import React from 'react';

const DepartmentDetailHero = ({ department }) => {
  return (
	<div className='w-full h-full'>
	  <img src={department.image} alt={department.name} />
	  <div className='mt-5 text-center'>
		<Title level={3}>{department.name}</Title>
	  </div>
	  <div className='mt-5 text-center px-5 md:px-10'>
		<Paragraph>{department.description}</Paragraph>
	  </div>
	</div>
  )
};

export default DepartmentDetailHero;
