import React from 'react'
import Shelf from '../../subassets/shelf.jpeg'
import Button from './Button'

const Consultation = () => {
  return (
    <section id='consult' className='consult'>
      <img src={Shelf} alt="shelf" />
      <div className="overlay">
        <h4 className='tophead'>
          we assure you that you win your case. no fees
        </h4>
        <a href="tel:+923475928881">
          <Button title='Call Us now' />
        </a>
      </div>
    </section>
  )
}

export default Consultation