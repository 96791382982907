/* eslint-disable react-hooks/exhaustive-deps */
import { App, Button, Form, Image, Spin } from 'antd';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { getTeamById } from '../../firebase/firebase';
import { ArrowLeftOutlined } from '@ant-design/icons';
import Title from 'antd/es/typography/Title';
import Paragraph from 'antd/es/typography/Paragraph';

const TeamDetailEdit = () => {
	const { notification } = App.useApp();
	const navigate = useNavigate();
	const {id} = useParams()

	const [team, setTeam] = useState(null)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		if (id) {
			getTeamDetail()
		}
	}, [id])

	const getTeamDetail = async () => {
		setLoading(true)
		getTeamById(id).then((data) => {
			setTeam(data)
		}).catch((error) => {
			notification.error({
				message: error.message
			});
		}).finally(() => {
			setLoading(false)
		})
	}

  return (
	<div>
	  	<div className='flex items-center justify-start gap-4'>
			<Button onClick={() => navigate(-1)}>
				<ArrowLeftOutlined />
			</Button>
			<Title level={5}>Edit Team member Detail</Title>
		</div>
		{loading && <div className='flex items-center justify-center mt-10'><Spin size='large' /></div>}
		{(!loading && team) &&(<>
			<div className='mt-10 bg-white rounded-md p-5 flex flex-col md:flex-row justify-between items-start gap-5'>
					<div className='w-full md:w-1/2'>
						<Title level={5}>{team?.name}</Title>
						<Paragraph>{team?.description}</Paragraph>
					</div>
					<div className='w-full md:w-1/2'>
						<Image src={team?.image} alt='department' />
					</div>
			</div>
			<div className='mt-10 bg-white rounded-md p-5'>
				<Title level={5} className='mt-4'>Edit datail</Title>
				<Form>
						
				</Form>
			</div>
		</>)}
	</div>
  )
}

export default TeamDetailEdit
