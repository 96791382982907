/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Navigation from '../Components/Navigation'
import Home from '../Components/sections/Home'
import Experience from '../Components/sections/Experience'
import Work from '../Components/sections/Work'
import Attorney from '../Components/sections/Attorney'
import Consultation from '../Components/sections/Consultation'
import Review from '../Components/sections/Review'
import Trust from '../Components/sections/Trust'
import Request from '../Components/sections/Request'
import Footer from '../Components/sections/Footer'
import Loader from 'react-loaders'
import { App } from 'antd'
import { getCompanies, getDepartments, getTeam } from '../firebase/firebase'

const MainPage = () => {
  const [team, setTeam] = useState([])
  const [companies, setCompanies] = useState([])
  const [departments, setDepartments] = useState([])
	const { notification } = App.useApp();
	const [loading, setLoading] = useState(false);

  useEffect(() => {
      getAllTeam()
      getAllCompanies()
      getAllDepartments()
    }, [])
  
    const getAllTeam = async () => {
      setLoading(true)
      getTeam().then((data) => {
        setTeam(data)
      }).catch((error) => {
        notification.error({
        message: error.message
      });
      }).finally(() => {
        setLoading(false)
      })
    }

    const getAllCompanies = async () => {
      setLoading(true)
      getCompanies().then((data) => {
        setCompanies(data)
      }).catch((error) => {
        notification.error({
        message: error.message
      });
      }).finally(() => {
        setLoading(false)
      })
    }

    const getAllDepartments = async () => {
      setLoading(true)
      getDepartments().then((data) => {
        setDepartments(data)
      }).catch((error) => {
        notification.error({
        message: error.message
      });
      }).finally(() => {
        setLoading(false)
      })
    }

  return (
	  <div className="app" data-scroll-container>
          <Navigation showRoutes />
          <Home />
          <Experience />
          <Work departments={departments} loading={loading} />
          <Attorney team={team} loading={loading} />
          <Consultation />
          <Review />
          <Trust companies={companies} loading={loading} />
          <Request />
          <Footer />
          <Loader active={loading} type="pacman"/>
        </div>
  )
}

export default MainPage
