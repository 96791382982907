import { App, Menu } from "antd";
import Sider from "antd/es/layout/Sider";
import { useEffect, useMemo, useRef } from "react";
import { useLocation, Link } from "react-router-dom";
import { BankOutlined, BuildOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGavel, faSignOut } from "@fortawesome/free-solid-svg-icons";
import { logout } from "../../../firebase/firebase";

const SideBar = ({ isSidebarCollapsed, setIsSidebarCollapsed }) => {
  const location = useLocation();
  const {notification, modal} = App.useApp();

  const items = useMemo(
    () =>
      [
        {
          key: "/",
          label: <Link to={'/backoffice/dashboard'}>{"Team"}</Link>,
          icon: <UsergroupAddOutlined />,
        },
        {
          key: "/tanks",
          label: <Link to={'/backoffice/clients'}>{"Trusted Companies"}</Link>,
          icon: <BankOutlined />,
        },
        {
          key: "/departments",
          label: <Link to={'/backoffice/department'}>{"Department"}</Link>,
          icon: <BuildOutlined />,
        },
      ]
        .map((tab) =>
          tab.children
            ? {
                ...tab,
                children: tab.children,
              }
            : tab
        ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const sidebarRef = useRef(null);

  // Handle clicks outside of the sidebar for small screens
  const handleClickOutside = (event) => {
    if (window.innerWidth < 1024) {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target)
      ) {
        setIsSidebarCollapsed(true);
      }
    }
  };

  useEffect(() => {
    if (!isSidebarCollapsed) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSidebarCollapsed]);

  const handleTabClick = () => {
    if (window.innerWidth < 1024) {
      setIsSidebarCollapsed(true);
    }
  };

  const handleLogout = () => {
	logout().then(() => {
		window.location.href = '/';
	}
	).catch((error) => {
		notification.error({
			message: error.message
		});
	});
  }

  const handleLogoutClick = () => {
	modal.confirm({
		title: 'Logout',
		content: 'Are you sure you want to logout?',
		onOk: handleLogout,
		onCancel: () => {}
	})
  }
  

  return (
    <Sider
      ref={sidebarRef}
      breakpoint="lg"
      collapsedWidth="0"
      width={280}
      trigger={null}
      collapsed={isSidebarCollapsed}
      onBreakpoint={(broken) => {
        setIsSidebarCollapsed(broken);
      }}
      style={{ backgroundColor: "#fff", zIndex: "5", scrollbarWidth: "none" }}
      className="!fixed !h-[calc(100vh_-_64px)] overflow-y-auto shadow-lg lg:!sticky lg:top-0 lg:!h-screen lg:shadow-none"
    >
      <div className="pb-5 pt-5">
        <div className="flex flex-col items-center">
          <div className='flex flex-col items-center logo'>
				<FontAwesomeIcon icon={faGavel} />
				<h5 className='mt-4'>S&S Law Associates</h5>
			</div>
          <div className="pt-5 pb-5">
            {/* <SettingButton /> */}
          </div>
        </div>
        <Menu
          mode="inline"
          selectedKeys={[location.pathname, location.pathname.split("/")[1]]}
          defaultOpenKeys={[
            "/" + location.pathname.split("/")[1],
            location.pathname.split("/")[1],
          ]}
          style={{ height: "100%" }}
          items={items}
          onClick={handleTabClick}
        />
		<div
			onClick={handleLogoutClick}
			className='flex flex-row gap-3 items-center absolute bottom-0 w-full p-5 cursor-pointer border-t border-gray-200 text-red-400 hover:text-red-900'>
			<FontAwesomeIcon icon={faSignOut} />
			<h5>Logout</h5>
		</div>
      </div>
	</Sider>
  );
};

export default SideBar;