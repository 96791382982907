/* eslint-disable react-hooks/exhaustive-deps */
import { ArrowLeftOutlined } from '@ant-design/icons'
import { App, Button, Form, Image, Spin } from 'antd'
import Title from 'antd/es/typography/Title'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getDepartmentById } from '../../firebase/firebase'
import Paragraph from 'antd/es/typography/Paragraph'

const DepartmentDetailEdit = () => {
	const navigate = useNavigate();
	const {notification} = App.useApp();

	const { id } = useParams();
	const [department, setDepartment] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (id) {
			getDepartmentDetail();
		}
	}, [id]);

	const getDepartmentDetail = async () => {
		setLoading(true);
		getDepartmentById(id).then((data) => {
			setDepartment(data);
		}).catch((error) => {
			notification.error({
				message: error.message
			});
		}).finally(() => {
			setLoading(false);
		})
	}

	return (
		<div>
			<div className='flex items-center justify-start gap-4'>
				<Button onClick={() => navigate(-1)}>
					<ArrowLeftOutlined />
				</Button>
				<Title level={5}>Edit Department Detail</Title>
			</div>
			{loading && <div className='flex items-center justify-center mt-10'><Spin size='large' /></div>}
			{(!loading && department) &&(<>
				<div className='mt-10 bg-white rounded-md p-5 flex flex-col md:flex-row justify-between items-start gap-5'>
					<div className='w-full md:w-1/2'>
						<Title level={5}>{department.name}</Title>
						<Paragraph>{department.description}</Paragraph>
					</div>
					<div className='w-full md:w-1/2'>
						<Image src={department.image} alt='department' />
					</div>
				</div>
				<div className='mt-10 bg-white rounded-md p-5'>
					<Form>
						
					</Form>
				</div>
			</>)}
		</div>
	)
}

export default DepartmentDetailEdit
