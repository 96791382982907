import { App, Button, Image, Table } from 'antd';
import Title from 'antd/es/typography/Title';
import React, { useEffect, useState } from 'react'
import { addDepartment, deleteDepartment, getDepartments, updateDepartment, uploadDepartmentImage } from '../../firebase/firebase';
import AddEditDepartmentModal from './components/AddEditDepartmentModal';
import { EyeOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const Department = () => {
	const { notification, modal } = App.useApp();
	const navigate = useNavigate();
	const [departments, setDepartments] = useState([]);
	const [loading, setLoading] = useState(false);

	const [showAddEditDepartmentModal, setAddEditDepartmentModal] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [departmentToEdit, setDepartmentToEdit] = useState(null);

	useEffect(() => {
		getAllDepartments()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const getAllDepartments = async () => {
		setLoading(true)
		getDepartments().then((data) => {
			setDepartments(data)
		}).catch((error) => {
			notification.error({
				message: error.message
			});
		}).finally(() => {
			setLoading(false)
		})
	}

	const uploadImage = async (image, uid) => {
					try{
						const imageUrl = await uploadDepartmentImage(image, uid);
						return imageUrl;
					} catch (error) {
						notification.error({
						message: error.message
					});
					}
				}

	const handleAddEditDepartment = async () => {
			setLoading(true)
					try {
						let imageUrl = departmentToEdit?.image;
			
						if (isEdit) {
							if (typeof departmentToEdit.image === 'object') {
								imageUrl = await uploadImage(departmentToEdit.image, departmentToEdit.key);
							}
							await updateDepartment({ ...departmentToEdit, image: imageUrl });
							notification.success({
								message: 'Department updated successfully',
							});
						} else {
							const newTeamMember = await addDepartment({ ...departmentToEdit, image: '', createdAt: new Date() });
							if (typeof departmentToEdit.image === 'object') {
								imageUrl = await uploadImage(departmentToEdit.image, newTeamMember.key);
								await updateDepartment({ ...newTeamMember, image: imageUrl });
							}
							notification.success({
								message: 'Department added successfully',
							});
						}
						getAllDepartments();
						setAddEditDepartmentModal(false);
						setDepartmentToEdit(null);
						setIsEdit(false);
					} catch (error) {
						console.log(error)
						notification.error({
							message: error.message,
						});
					} finally {
						setLoading(false);
					}
		};

	const handleDeleteClick = (uid) => {
		modal.confirm({
			title: 'Are you sure you want to delete this company?',
			onOk: () => {
				setLoading(true)
				deleteDepartment(uid).then(() => {
					notification.success({
						message: 'Company deleted successfully',
					});
					getAllDepartments();
				}).catch((error) => {
					notification.error({
						message: error.message
					});
				}).finally(() => {
					setLoading(false)
				})
			}
		})
	}

	const handleDetailClick = (uid) => {
		navigate(`/backoffice/department/detail/${uid}`)
	}
  return (
	<div>
	  <div className='flex flex-col md:flex-row justify-between items-center'>
		<Title level={5}>Departments</Title>
		<Button
			type="primary"
			onClick={() => {
				setAddEditDepartmentModal(true);
				setIsEdit(false);
			}}
		>Add New</Button>
	</div>
	<div className="flex flex-col gap-4 bg-white mt-10">
		<Table
			loading={loading}
			dataSource={departments}
			columns={[
				{
					title: "Name",
					dataIndex: "name",
					key: "name",
				},
				{
					title: "Description",
					width: 250,
					dataIndex: "description",
					key: "description",
					render: (text) => {
							return (
								<p style={{ 
									overflow: 'hidden', 
									textOverflow: 'ellipsis', 
									display: '-webkit-box', 
									WebkitLineClamp: 2, 
									WebkitBoxOrient: 'vertical' 
								}}>
									{text}
								</p>
							)
						}
				},
				{
					title: "Image",
					dataIndex: "image",
					key: "designation",
					render: (text) => <Image height={50} width={100} src={text} />
				},
				{
					title: "Actions",
					key: "actions",
					render: (text, record) => (
						<div>
							<Button
								color="primary"
								size="small"
								variant="outlined"
								onClick={() => {
									setDepartmentToEdit(record)
									setIsEdit(true)
									setAddEditDepartmentModal(true)
								}}
							>Edit</Button>
							<Button
								color="danger"
								size="small"
								variant="outlined"
								className="ml-2"
								onClick={() => {
									handleDeleteClick(record.key)
								}
								}
							>Delete</Button>
						</div>
					),
				},
				{
					title: "Detail",
					key: "detail",
					render: (text, record) => (
						<Button
							color="primary"
							size="small"
							variant="outlined"
							onClick={() => {
								handleDetailClick(record.key)
							}}
							icon={<EyeOutlined />}
							iconPosition='left'
						>Detail</Button>
					)
				}
			]}
			pagination={false}
			scroll={{ x: 'max-content' }}
		/>
		</div>
			<AddEditDepartmentModal
				isEdit={isEdit}
				showAddEditDepartmentModal={showAddEditDepartmentModal}
				addEditDepartment={handleAddEditDepartment}
				onCancel={() => {
					setAddEditDepartmentModal(false);
					setDepartmentToEdit(null);
				}}
				departmentToEdit={departmentToEdit}
				setDepartmentToEdit={setDepartmentToEdit}
				loading={loading}
			/>
	</div>
  )
}

export default Department
