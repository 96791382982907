import { Form, Modal, Input, Image } from 'antd';
import React from 'react'

const AddEditCompanyDialog = ({
	isEdit,
	showAddEditCompanyModal,
	addEditCompany,
	onCancel,
	companyToEdit,
	setCompanyToEdit,
	loading
}) => {

	const getImageSrc = () => {
		if (!companyToEdit?.image) return;
		if (typeof companyToEdit.image === 'string') return companyToEdit.image;
		return URL.createObjectURL(new Blob([companyToEdit.image]));
	};

return (
<Modal
				title={isEdit ? "Edit trusted Company" : "New trusted company"}
				open={showAddEditCompanyModal}
				onOk={addEditCompany}
				onCancel={onCancel}
		cancelButtonProps={{ disabled: loading }}
		okButtonProps={{
			disabled: (!companyToEdit?.name || !companyToEdit?.image),
			loading: loading,
		}}
		>
		<Form.Item label={"Name"} layout="vertical">
			<Input
					name='name'
							placeholder={"Name"}
				value={companyToEdit?.name}
				onChange={(e) => setCompanyToEdit((prev) => ({ ...prev, [e.target.name]: e.target.value }))}
						/>
				</Form.Item>
		<Form.Item label={"Image"} layout="vertical">
			<Input
				type='file'
					name='image'
				placeholder={"Image"}
				onChange={(e) => setCompanyToEdit((prev) => ({ ...prev, [e.target.name]: e.target.files[0] }))}
				/>
		</Form.Item>
		<Image height={100} width={100} src={getImageSrc()} />
	</Modal>
)
}

export default AddEditCompanyDialog
