/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getDepartmentById } from '../firebase/firebase'
import { App, Spin } from 'antd'
import Navigation from '../Components/Navigation'
import DepartmentDetailHero from '../Components/department/DepartmentDetailHero'

const DepartmentDetail = () => {
	const {id} = useParams()
	const [department, setDepartment] = useState(null)
	const [loading, setLoading] = useState(false)
	const { notification } = App.useApp();
	useEffect(() => {
		if (id) {
			getDepartment()
		}
	}, [id])

	const getDepartment = async () => {
		setLoading(true)
		getDepartmentById(id).then((data) => {
			setDepartment(data)
		}
		).catch((error) => {
			notification.error({
        		message: error.message
      		});
		}).finally(() => {
			setLoading(false)
		})
	}

	if (loading && !department) {
		return <div className='flex justify-center items-center mt-10'><Spin size='large' spinning /></div>
	}

	return (
		<main className="app" data-scroll-container>
			<Navigation />
			{(!loading && department) && <DepartmentDetailHero department={department} />}
		</main>
	)
}

export default DepartmentDetail
